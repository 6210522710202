/** @format */

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import userReducer from "./reducers/userReducers";
import uiReducer from "./reducers/uiReducers";
import borderRed from "./reducers/redBorderReducer";
import loadWidjetOnMasquerading from "./reducers/loadWidjetOnMasquerading";
import userNameAndRole from "./reducers/userNameReducer";
import hideAndShowReducer from "./reducers/hideAndShowReducer";
import setSearchTextReducer from "./reducers/searchReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import { composeWithDevTools } from "redux-devtools-extension";

function saveToLocalStorage(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.error(e);
  }
}
function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

const rootReducer = combineReducers({
  user: userReducer,
  UI: uiReducer,
  borderRed: borderRed,
  loadWidjetOnMasquerading: loadWidjetOnMasquerading,
  userNameAndRole: userNameAndRole,
  hideAndShowReducer: hideAndShowReducer,
  setSearchTextReducer: setSearchTextReducer,
  dashboard: dashboardReducer,
});

const persistedState = loadFromLocalStorage();

const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(thunk))
);

store.subscribe(() => saveToLocalStorage(store.getState()));
export default store;
