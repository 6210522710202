import { SET_BORDER_RED_FALSE, SET_BORDER_RED_TRUE } from "../types";

export const setBorderRedTrue = () => {
  return {
    type: SET_BORDER_RED_TRUE,
  };
};

export const setBorderRedFalse = () => {
  return {
    type: SET_BORDER_RED_FALSE,
  };
};
