import { SET_BORDER_RED_FALSE, SET_BORDER_RED_TRUE } from "../types";

const initialState = {
  isBorderRed: false,
};

const borderRed = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_BORDER_RED_TRUE:
      return {
        ...state,
        isBorderRed: true,
      };
    case SET_BORDER_RED_FALSE:
      return {
        ...state,
        isBorderRed: false,
      };

    default:
      return state;
  }
};

export default borderRed;
