import { CLEAR_DASHBOARD_URL, SET_DASHBOARD_URL } from "../types";

const initialState = {
  dashboardUrl: "",
};

const dashboardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_DASHBOARD_URL:
      return {
        ...state,
        dashboardUrl: "",
      };
    case SET_DASHBOARD_URL:
      return {
        ...state,
        dashboardUrl: action.payload.dashboardUrl,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
