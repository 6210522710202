import React, { useState, useEffect, useContext, useRef } from "react";
import { withRouter } from "react-router";
import { ROLE } from "../../constants";
import { roleNames } from "../../enums";
import { Toast_Func } from "../../helpers/toast.helper";
import axios from "../../axios/axios";
import classes from "./NavBarLinks.module.scss";

const GlobalAlert: React.FunctionComponent<any> = ({ history }) => {
  const role = localStorage.getItem(ROLE) || sessionStorage.getItem(ROLE);
  const [globalAlert, setGlobalAlert] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (role != null) {

      let global_switch
      if (typeof process.env.REACT_APP_GLOBAL_ALERT_SWITCH !== "undefined") {
        global_switch = process.env.REACT_APP_GLOBAL_ALERT_SWITCH;
      } else {
        global_switch = "no";
      }
      
      if(global_switch == "yes") {
        setIsLoading(true);
        axios
          .get(`global_alert/${roleNames[role]}/control center`)
          .then((response) => {
            setGlobalAlert(response.data.data);
            setIsLoading(false);
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              Toast_Func({ status: false, message: error.response.data.message });
            } else {
              Toast_Func({ status: false, message: "Data Not found" });
            }
          });
      }
    }
  }, [role]);

  return (
    <>
      {!isLoading &&
        (globalAlert && globalAlert.id ? (
          <div
            className={classes.top_banner}
            style={{ backgroundColor: `${globalAlert.color_hex_code}` }}
          >
            <p className={classes.p_banner}>{globalAlert.alert_message}</p>
          </div>
        ) : (
          ""
        ))}
    </>
  );
};
export default withRouter(GlobalAlert);
