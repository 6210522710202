import React, { useState, useEffect, useContext, useRef } from "react";
import { RouteComponentProps, useLocation, withRouter } from "react-router";
import {
  NAME,
  ROLE,
  USER_ID,
  MASQUERADE,
  GRANT_TYPE_VALUE,
  CLIENT_ID_VALUE,
  CLIENT_SECRET_VALUE,
  SCOPE,
  TOKEN,
  USER_ID_ORIGINAL,
  ORIGINAL_ROLE,
} from "../../constants";
import classes from "./NavBarLinks.module.scss";
import Image from "../Atoms/Image/Image";
import { Nav, NavDropdown } from "react-bootstrap";
import { Row, Col, Form, Card, Button } from "react-bootstrap";
import Breadcrumbs from "../../components/BreadCrumb/BreadCrumbs";
import { FaSearch } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import axios from "../../axios/axios";

import { AuthContext } from "../../containers/auth/AuthContext";
import { Toast_Func } from "../../helpers/toast.helper";
import _ from "lodash";
import {
  setBorderRedFalse,
  setBorderRedTrue,
} from "../../redux/actions/redBorderAction";
import {
  setRoleName,
  setUserName,
} from "../../redux/actions/setUserNameAction";
import {
  setShowDropDownFalse,
  setShowDropDownTrue,
} from "../../redux/actions/hideAndShowAction";

import { setLoadWidjetTrue } from "../../redux/actions/loadWidjetsOnMasqueradingAction";
import { useSelector, useDispatch } from "react-redux";
import { getJSDocClassTag } from "typescript";
import { setLoadWidjetFalse } from "../../redux/actions/loadWidjetsOnMasqueradingAction";
import { clearDashboardUrl } from "../../redux/actions/dashboardActions";

interface NavbarLinksPropsInterface extends RouteComponentProps {
  logOut: () => void;
  logoutMasqueradedUser: (callback: any) => void;
}

const NavbarLinks: React.FunctionComponent<NavbarLinksPropsInterface> = ({
  history,
  logOut,
  logoutMasqueradedUser,
}) => {
  const name = localStorage.getItem(NAME) || sessionStorage.getItem(NAME);
  const role = localStorage.getItem(ROLE) || sessionStorage.getItem(ROLE);
  const id = localStorage.getItem(USER_ID) || sessionStorage.getItem(USER_ID);
  const location = useLocation();
  const [iconToggle, setIconToggle] = useState<boolean>(false);
  const [toggleClass, setToggleClass] = useState<string>(
    classes.accordion_toggle
  );
  const [isChecked, setIsChecked] = useState("0");
  const [opendKeys, setOpenedKeys] = useState<any>([]);
  const [check, setCheck] = useState(null);
  const [subordinates, setSubOrdinates] = useState<any>([]);
  const [isSubOrdinatesLoading, setSubOrdinatesLoading] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const [timeOutId, setTimeOutId] = useState<any>(null);
  const [issearchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [clear, setClear] = useState<boolean>(false);

  function pushingToDashBoard() {
    dispatch(setLoadWidjetTrue());
    // authContext.setLoadWidjetsOnLogIn(!authContext.loadWidjetsOnLogin);
    // window.location.reload(true);
  }

  const prevElement = useRef();
  const [isLoading, setLoading] = useState<boolean>(false);
  const authContext = useContext(AuthContext);

  const URL = "/operator/customer-lookup";

  const isBorderRed = useSelector((state: any) => {
    return state.borderRed.isBorderRed;
  });
  const username = useSelector((state: any) => {
    return state.userNameAndRole.userName;
  });
  const rolename = useSelector((state: any) => {
    return state.userNameAndRole.roleName;
  });
  const showDropDown = useSelector((state: any) => {
    return state.hideAndShowReducer.show;
  });

  const handleChecboxChange = (e: any) => {
    // setIsChecked(!isChecked);
    const isCatering = localStorage.getItem("is_caterings");
    localStorage.setItem("is_caterings", isCatering == "1" ? "0" : "1");
    setIsChecked(isCatering == "1" ? "0" : "1");
  };
  useEffect(() => localStorage.setItem("is_caterings", "0"), []);

  useEffect(() => {
    const __tokenmask__: any = getCookie("__tokenmask__");
    const __suid__: any = getCookie("__suid__");
    const __suidmask__ = getCookie("__suidmask__");
    let DOMAIN: any;

    if (typeof process.env.REACT_APP_DOMAIN !== "undefined") {
      DOMAIN = process.env.REACT_APP_DOMAIN;
    } else {
      DOMAIN = ".zupas.com";
    }

    // legacy masquerade should be reset whenever user comes to control center
    document.cookie = `__suidmask__=; Max-Age=0; domain=${DOMAIN};`;

    if (isBorderRed && typeof __tokenmask__ === "undefined") {
      dispatch(setBorderRedFalse());

      const original_token: any = getCookie("__token__");

      localStorage.setItem(TOKEN, original_token);

      // replace user id
      const original_user_id = localStorage.getItem(USER_ID_ORIGINAL) || "";
      localStorage.removeItem(USER_ID_ORIGINAL);
      localStorage.setItem(USER_ID, original_user_id);
      // replace user role
      if (localStorage.getItem(ORIGINAL_ROLE)) {
        let temp = localStorage.getItem(ORIGINAL_ROLE);
        // @ts-ignore: Unreachable code error
        localStorage.setItem(ROLE, temp);
      }
      //after changing auth token we will make api call for widjets
      dispatch(setLoadWidjetFalse());
    }

    if (
      typeof __tokenmask__ !== "undefined" &&
      __tokenmask__ !== localStorage.getItem(TOKEN)
    ) {
      localStorage.setItem(TOKEN, __tokenmask__);
      document.cookie =
        "__suidold__=" + __suid__ + `; Max-Age=86400; domain=${DOMAIN};`;
      document.cookie =
        "__suid__=" + __suidmask__ + `; Max-Age=86400; domain=${DOMAIN};`;
      document.cookie =
        "__suid__=" + __suidmask__ + `; Max-Age=0; domain=${DOMAIN};`;
    }
    if (!isBorderRed) {
      getSubordinates();
    }
  }, [isBorderRed]);
  function getCookie(name: any) {
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
  }
  const onMasscrading = (
    id: any,
    username: any,
    role: any,
    showDropDown: boolean
  ) => {
    setLoading(true);
    history.push("/dashboard");

    axios
      .post(`${MASQUERADE}/${id}`, {
        password: "",
        username: "",
        grant_type: GRANT_TYPE_VALUE,
        client_id: CLIENT_ID_VALUE,
        client_secret: CLIENT_SECRET_VALUE,
        scope: SCOPE,
      })
      .then((response: any) => {
        dispatch(setShowDropDownFalse());
        dispatch(setRoleName(role));
        dispatch(setUserName(username));
        setOpenedKeys([]);
        setCheck(null);

        setLoading(false);
        authContext.authenticateUser(response.data.access_token); // storing masscaraded user in AUTH_TOKEN in localStorage

        let DOMAIN: any;

        if (typeof process.env.REACT_APP_DOMAIN !== "undefined") {
          DOMAIN = process.env.REACT_APP_DOMAIN;
        } else {
          DOMAIN = ".zupas.com";
        }
        const original_user_id = localStorage.getItem(USER_ID) || "";
        localStorage.setItem(USER_ID_ORIGINAL, original_user_id);

        localStorage.setItem(USER_ID, id);
        localStorage.setItem(ROLE, role);
        let suidOld: any = getCookie("__suid__"); // getting original suid
        document.cookie = `__suidold__= ${suidOld} ; Max-Age=86400; domain=${DOMAIN}; `;
        document.cookie =
          "__suidold__=" + suidOld + `; Max-Age=86400; domain=${DOMAIN};`; // storing original suid in suidold
        if (typeof response.headers["set-cookie"] !== "undefined") {
          // storing masscraded suid in __suid__
          document.cookie = response.headers["set-cookie"];
        } else if (typeof response.data["__suid__"] !== "undefined") {
          document.cookie =
            "__suid__=" +
            response.data["__suid__"] +
            `; Max-Age=86400; domain=${DOMAIN};`;

          document.cookie =
            "__guid__=" +
            response.data["__suid__"] +
            `; Max-Age=86400; domain=${DOMAIN};`;
        }

        if (typeof response.data["access_token"] !== "undefined") {
          document.cookie =
            "__tokenmask__=" +
            response.data["access_token"] +
            `; Max-Age=86400; domain=${DOMAIN};`;

          dispatch(setBorderRedTrue());
        }
        pushingToDashBoard();
      })
      .catch((error) => {
        if (
          typeof error.response !== "undefined" &&
          typeof error.response.data !== "undefined"
        ) {
          Toast_Func({ status: false, message: error.response.data.message });
        } else {
          Toast_Func({ status: false, message: "Unknown error occurs" });
        }
        // setLoading(false);
        // setFormState(values);
      });
  };
  const getSubordinates = () => {
    // let role_id : string =localStorage.getItem("ROLE_ID")
    setSubOrdinatesLoading(true);
    axios
      .get(`masquerade/subordinates`)
      .then((response) => {
        setSubOrdinates(response.data.data.data);
        setSubOrdinatesLoading(false);
      })
      .catch((error) => {
        if (
          typeof error.response !== "undefined" &&
          typeof error.response.data !== "undefined"
        ) {
          //   Toast_Func({status:false, message: error.response.data.message})
        } else {
          /// Toast_Func({status:false, message: 'Data Not found'})
        }
      });
  };

  const changePassword = () => {
    history.push(`/users/changePassword/${id}`);
  };

  const handleSearch = (e: any) => {
    setSearchLoading(true);
    setClear(false);
    let value: any = "";
    if (e.target && e.target.value) {
      value = e.target.value;
    }
    setSearchText(value);

    if (timeOutId) {
      clearTimeout(timeOutId);
    }

    const timeOut = setTimeout(function () {
      axios
        .get(`masquerade/subordinates?search=${value}`)
        .then((response) => {
          setSubOrdinates(response.data.data.data);
          setSearchLoading(false);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            Toast_Func({ status: false, message: error.response.data.message });
          } else {
            Toast_Func({ status: false, message: "Data Not found" });
          }
        });
    }, 500);

    setTimeOutId(timeOut);
  };

  const handleClearSearch = () => {
    setClear(true);
    handleSearch("");
  };

  const onProfileClick = () => {
    dispatch(setShowDropDownTrue());
  };

  const toggleClassess = (id: any) => {
    setCheck(id);
    const arr: any = [];
    if (check !== id) {
      arr.push(id);
      setOpenedKeys(arr);
    } else {
      setOpenedKeys([]);
      setCheck(null);
    }
  };

  const pathnames = window.location.pathname.split("/").filter((x) => x);
  let customClass =
    pathnames[0] === "menus" ||
    pathnames[0] === "menu-markets" ||
    pathnames[0] === "items" ||
    pathnames[0] === "ingredients" ||
    pathnames[0] === "categories"
      ? classes.nav_with_sidebar
      : "";

  return (
    <div className={`${customClass} ${classes.nav_container1}`}>
      <Row className={classes.header}>
        <Col md={5} sm={5} xs={6} className={classes.logo_container}>
          <div className={classes.logo_wrap}>
            <Image
              onClick={() => {
                history.push("/");
              }}
              className={classes.brand_image}
              source={require("../../images/Header/logo.png").default}
              alt=""
            />
          </div>
          <Breadcrumbs className={classes.breadcrumbs_wrap} />
        </Col>
        <Col
          md={5}
          sm={3}
          xs={6}
          className={
            classes.logo_container + " d-none d-sm-flex justify-content-between"
          }
        >
          <Image
            onClick={() => {
              dispatch(clearDashboardUrl());
              history.push("/");
            }}
            className={classes.max_width_100}
            source={
              require("../../images/Dashboard/control_center_icon.png").default
            }
            alt=""
          />
          {location.pathname === URL ? (
            <div className="btnContainr d-flex">
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={handleChecboxChange}
                  checked={isChecked == "0" ? false : true}
                />
                <div className="slider">
                  <span>Individual</span>
                  <span>Catering</span>
                </div>
              </label>
            </div>
          ) : null}
        </Col>
        <Col
          md={2}
          sm={4}
          xs={6}
          className={classes.logo_container + " " + classes.align_right}
        >
          <NavDropdown
            title={
              <Row
                onClick={onProfileClick}
                className={classes.header_profile_box}
              >
                <Image
                  className={classes.header_profile_image}
                  source={
                    require("../../images/Header/profile_image.svg").default
                  }
                  alt=""
                />
                <div className={"d-none d-sm-block"}>
                  <div className={classes.user_name}>
                    {isBorderRed ? username : name}
                  </div>
                  <div className={classes.role_name}>
                    {isBorderRed ? (
                      <Image
                        className={`${classes.arrow_icon_wrap}`}
                        source={
                          require("../../images/Market/selected-glasses-icon.svg")
                            .default
                        }
                        alt=""
                      />
                    ) : (
                      ""
                    )}{" "}
                    {isBorderRed ? rolename : role}
                  </div>
                </div>
                <Image
                  className={`${classes.header_profile_drop}`}
                  source={
                    require("../../images/Header/profile_drop.png").default
                  }
                  alt=""
                />
              </Row>
            }
            id="basic-nav-dropdown"
            className={
              isBorderRed
                ? "user_profile_actions_list active-profile"
                : "user_profile_actions_list"
            }
          >
            <NavDropdown.Item className={classes.user_info_action}>
              <div className={classes.user_name}>{name}</div>
              <div className={classes.role_name}>
                {" "}
                <Image
                  className={`${classes.arrow_icon_wrap}`}
                  source={
                    require("../../images/Market/selected-glasses-icon.svg")
                      .default
                  }
                  alt=""
                />{" "}
                {role}
              </div>
            </NavDropdown.Item>
            {/* Inner Dropmenu */}
            {!showDropDown ? (
              ""
            ) : (
              <NavDropdown
                id="basic-nav-dropdown2"
                className={
                  classes.inner_drop_wrap +
                  " user_profile_actions_list inner-drop-wrap"
                }
                title={
                  <p
                    className={
                      classes.user_profile_action +
                      " m-0 dropdown-item d-flex align-items-center justify-content-between"
                    }
                  >
                    {isBorderRed || subordinates.length === 0 ? (
                      ""
                    ) : (
                      <React.Fragment>
                        Masquerade
                        <Image
                          className={`${classes.arrow_icon_wrap}`}
                          source={
                            require("../../images/Market/drop-arrow-icon.svg")
                              .default
                          }
                          alt=""
                        />
                      </React.Fragment>
                    )}
                  </p>
                }
              >
                <div className={classes.user_profile_innr_wrap}>
                  <div className={classes.searching_box}>
                    <Form.Control
                      className={classes.search_field}
                      id={"search"}
                      type="text"
                      name={"apps_search"}
                      placeholder={"Search"}
                      autoComplete={"off"}
                      value={clear ? "" : searchText}
                      onChange={(event) => handleSearch(event)}
                    />
                    <button
                      type="button"
                      className={
                        searchText.length > 0
                          ? classes.value_in +
                            " btn btn-link " +
                            classes.search_clear_icon
                          : classes.search_clear_icon + " btn btn-link"
                      }
                      onClick={handleClearSearch}
                    >
                      <Image
                        source={
                          require("../../images/Market/search-clear-icon.svg")
                            .default
                        }
                        alt=""
                      />
                    </button>
                    <div className={classes.listing_search_icon}>
                      <FaSearch style={{ color: "#5092A4" }} />
                    </div>
                  </div>
                  {/* Accordion list */}
                  <div
                    className={
                      classes.accordion_custom_list + " accordion_custom_list"
                    }
                  >
                    {
                      <Accordion
                        defaultActiveKey={"3"}
                        className={classes.accordion_wrapper}
                      >
                        {issearchLoading || isSubOrdinatesLoading ? (
                          <h6 className="text-center mb-0">Loading</h6>
                        ) : subordinates && subordinates.length > 0 ? (
                          subordinates.map((subordinate: any) => {
                            return subordinate.user.length > 0 ? (
                              <Card
                                key={subordinate.id}
                                className={classes.accordion_card}
                              >
                                <Card.Header
                                  className={classes.accordion_header}
                                >
                                  <Accordion.Toggle
                                    onClick={() =>
                                      toggleClassess(subordinate.id)
                                    }
                                    as={Button}
                                    className={
                                      opendKeys.includes(subordinate.id)
                                        ? classes.accordion_toggle +
                                          " " +
                                          classes.iconToggle
                                        : classes.accordion_toggle
                                    }
                                    variant="link"
                                    eventKey={subordinate.id}
                                    key={subordinate.id}
                                  >
                                    {subordinate.name}
                                  </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse
                                  className={
                                    searchText.length > 0 ? "show" : ""
                                  }
                                  eventKey={subordinate.id}
                                >
                                  <Card.Body className="py-0">
                                    <ul
                                      className={
                                        classes.accordion_list_wrap +
                                        " list-inline p-0"
                                      }
                                    >
                                      {subordinate.user.map((user: any) => (
                                        <li
                                          onClick={() => {
                                            onMasscrading(
                                              user.id,
                                              user.username,
                                              subordinate.name,
                                              true
                                            );
                                          }}
                                          key={user.id}
                                        >
                                          <a>{user.full_name}</a>
                                        </li>
                                      ))}
                                    </ul>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            ) : (
                              ""
                            );
                          })
                        ) : (
                          <h6 className="text-center px-3">Not Found</h6>
                        )}

                        {/*<Card className={classes.accordion_card}>
  <Card.Header className={classes.accordion_header}>
    <Accordion.Toggle
      onClick={() => setIconToggle(!iconToggle)}
      as={Button}
      className={
        iconToggle
          ? classes.accordion_toggle +
            " " +
            classes.iconToggle
          : classes.accordion_toggle
      }
      variant="link"
      eventKey="1"
    >
      Admins
    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="1">
    <Card.Body>Hello! I'm the body</Card.Body>
  </Accordion.Collapse>
</Card>
<Card className={classes.accordion_card}>
  <Card.Header className={classes.accordion_header}>
    <Accordion.Toggle
      onClick={() => setIconToggle(!iconToggle)}
      as={Button}
      className={
        iconToggle
          ? classes.accordion_toggle +
            " " +
            classes.iconToggle
          : classes.accordion_toggle
      }
      variant="link"
      eventKey="2"
    >
      Admins
    </Accordion.Toggle>
  </Card.Header>
  <Accordion.Collapse eventKey="2">
    <Card.Body>Hello! I'm the body</Card.Body>
  </Accordion.Collapse>
</Card>*/}
                      </Accordion>
                    }
                  </div>
                </div>
              </NavDropdown>
            )}
            {/* Inner Dropmenu End */}

            {
              <NavDropdown.Item
                className={classes.user_profile_action}
                onClick={() => changePassword()}
              >
                Change Password
              </NavDropdown.Item>
            }

            {isBorderRed ? (
              " "
            ) : (
              <NavDropdown.Item
                onClick={logOut}
                className={
                  classes.logout_item + " " + classes.user_profile_action
                }
              >
                Logout
              </NavDropdown.Item>
            )}
            <NavDropdown.Divider
              className={classes.user_profile_actions_divider}
            />
            {isBorderRed ? (
              <NavDropdown.Item
                onClick={() => logoutMasqueradedUser(setSearchText)}
                className={
                  classes.logout_item + " " + classes.user_profile_action
                }
              >
                Back as {localStorage.getItem("AUTH_NAME")}
              </NavDropdown.Item>
            ) : (
              ""
            )}
          </NavDropdown>

          <div className={classes.vl}></div>
          <nav></nav>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(NavbarLinks);
