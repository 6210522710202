import { SET_USERNAME, SET_ROLENAME } from "../types";

export const setUserName = (username: any) => {
  return {
    type: SET_USERNAME,
    payload: username,
  };
};

export const setRoleName = (roleName: any) => {
  return {
    type: SET_ROLENAME,
    payload: roleName,
  };
};
