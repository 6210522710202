import React from "react";

import {
  IProvider,
  IAuthPermissions,
  IAction,
  ActionKind,
  initialState,
  authPermissionsState,
  authPermissionsDispatch,
  IContextState,
} from "./AuthPermissionsInteface";

let permissionsStateRef: IContextState | null = initialState;
function ConnectStatusReducer(state: IContextState, action: IAction) {
  switch (action.type) {
    case ActionKind.add_auth_permissions:
      return { isFetched: true, permissions: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Store provider
const AuthPermissionsProvider = ({ children }: IProvider) => {
  const [state, dispatch] = React.useReducer(
    ConnectStatusReducer,
    initialState
  );
  return (
    <authPermissionsState.Provider value={state}>
      <authPermissionsDispatch.Provider value={dispatch}>
        {children}
      </authPermissionsDispatch.Provider>
    </authPermissionsState.Provider>
  );
};

// Store state
function useAuthPermissionsState() {
  const context = React.useContext(authPermissionsState);
  permissionsStateRef = context;
  if (context === undefined) {
    throw new Error(
      "useAuthPermissionsState must be used inside a AuthPermissionsProvider"
    );
  }
  return context;
}

// Store dispatch
function useAuthPermissionsDispatch() {
  const context = React.useContext(authPermissionsDispatch);
  if (context === undefined) {
    throw new Error(
      "useAuthPermissionsDispatch must be used inside a AuthPermissionsProvider"
    );
  }
  return context;
}

function getPermissionsStateRef() {
  return permissionsStateRef;
}

// An action to add permissions in store
function add_auth_permissions(
  dispatch: React.Dispatch<any> | null,
  data: IAuthPermissions
) {
  dispatch &&
    dispatch({
      type: ActionKind.add_auth_permissions,
      payload: data,
    });
}

export {
  AuthPermissionsProvider,
  useAuthPermissionsState,
  useAuthPermissionsDispatch,
  add_auth_permissions,
  getPermissionsStateRef,
};
