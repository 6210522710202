import { createContext, Dispatch } from "react";

export enum ActionKind {
  add_auth_permissions = "ADD_AUTH_PERMISSIONS",
}
export interface IModulePermissions {
  id: number;
  name: string;
  permissions: { id: number; name: string; app_id: number }[];
}
export interface IAuthPermissions extends Array<IModulePermissions> {}

export interface IContextState {
  permissions: IAuthPermissions;
  isFetched: boolean;
}

export type IAction = {
  type: ActionKind;
  payload: IAuthPermissions;
};

export interface IProvider {
  children: React.ReactNode;
}

export const initialState: IContextState = {
  permissions: [],
  isFetched: false,
};

export const authPermissionsState = createContext<IContextState | null>(null);
export const authPermissionsDispatch = createContext<Dispatch<any> | null>(
  null
);
