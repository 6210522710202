import { SET_USERNAME, SET_ROLENAME } from "../types";

const initialState = {
  userName: "",
  roleName: "",
};

const userNameAndRole = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USERNAME:
      return {
        ...state,
        userName: action.payload,
      };
    case SET_ROLENAME:
      return {
        ...state,
        roleName: action.payload,
      };

    default:
      return state;
  }
};

export default userNameAndRole;
