import React from 'react';

interface ImageProps {
    source: string;
    alt?: string;
    className?: string;
    style?: object;
    onClick?: any;
}
const Image: React.FunctionComponent<ImageProps> = ({className, source, alt, style , onClick}) => {
    
    return <div>
        { onClick ? (
        <img className={className} onClick={()=>onClick()} src={source} alt={alt} style={style}/>
          ) :(
        <img className={className} src={source} alt={alt} style={style}/>
         ) }
    </div>
}

export default Image;