import React, { useContext, useEffect } from "react";
import { RouteProps, Route, Redirect, useHistory } from "react-router";
import { AuthContext } from "../../containers/auth/AuthContext";
import { MY_PERMISSIONS, ROUTE_LOGIN } from "../../constants";
import {
  useAuthPermissionsState,
  useAuthPermissionsDispatch,
  add_auth_permissions,
} from "../../containers/authPermissions/AuthPermissionsContext";
import Spinner from "../Spinner/Spinner";
import axios from "../../axios/axios";
import { Toast_Func } from "../../helpers/toast.helper";

interface AuthorizedRouteProps extends RouteProps {
  component: any;
  parentModule: string;
  childModule?: string;
}

const AuthorizedRoute: React.FunctionComponent<AuthorizedRouteProps> = ({
  component: Component,
  parentModule,
  childModule,
  ...rest
}) => {
  const history = useHistory();
  const authValues = useContext(AuthContext);
  const authPermissionState = useAuthPermissionsState();
  const authPermissionDispatch = useAuthPermissionsDispatch();

  // Hook to fetch permissions from api if it isn't already available in store
  useEffect(() => {
    !authPermissionState?.isFetched &&
      axios(`${MY_PERMISSIONS}`).then((res) => {
        add_auth_permissions(authPermissionDispatch, res.data.data);
      });
  }, []);

  // A function to handle a case when unauthorized user try to access some route
  const handleUnauthorization = () => {
    Toast_Func({
      status: false,
      message: "User does not have any of the necessary access rights",
    });
    history.goBack();
    return false;
  };

  // A function to check, the route which user are going to access have authorization or not
  const isAuthorized = () => {
    if (parentModule) {
      const modulePermissions = authPermissionState?.permissions?.find(
        (permission) => permission.name === parentModule
      );
      if (modulePermissions) {
        if (childModule) {
          if (
            modulePermissions.permissions.find(
              (permission) => permission.name === childModule
            )
          )
            return true;
          else handleUnauthorization();
        } else return true;
      } else handleUnauthorization();
    } else return true;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        authValues.checkAuthentication() ? (
          authPermissionState?.isFetched ? (
            isAuthorized() ? (
              <Component {...props} key={document.location.href}/>
            ) : (
              <></>
            )
          ) : (
            <Spinner lightBg={true} />
          )
        ) : (
          <Redirect to={ROUTE_LOGIN} />
        )
      }
    />
  );
};

export default AuthorizedRoute;
