import { SET_SHOW_DROPDOWN_FALSE, SET_SHOW_DROPDOWN_TRUE } from "./../types";

export const setShowDropDownTrue = () => {
  return {
    type: SET_SHOW_DROPDOWN_TRUE,
  };
};

export const setShowDropDownFalse = () => {
  return {
    type: SET_SHOW_DROPDOWN_FALSE,
  };
};
