import {
  MarkItem86FormFields,
  ItemConfig,
  StatusDetails,
} from "./models/item.model";
import { Toast_Func } from "./helpers/toast.helper";
import { getPermissionsStateRef } from "./containers/authPermissions/AuthPermissionsContext";
import { categoriesName, categoriesNameCatering } from "./constants";
import moment from "moment";
import { IActionDetails } from "./components/TableRowActionsList/ActionsList";

export function getCookie(name: any) {
  var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
}

//To group locations if their start_date and end_date is same

export const locationsGrouping = (locations: any) => {
  const filteredLocations = locations
    .filter((location: any) => location.pivot.status)
    .map((location: any) => ({
      start_date: location.pivot.start_date || "",
      end_date: location.pivot.end_date || "",
      reason: location.pivot.reason || "",
      status: location.pivot.status || null,
      id: location.pivot.location_id,
    }));
  const groupedLocations: MarkItem86FormFields[] = [];

  filteredLocations.forEach((location: any) => {
    const exisitingEntryIndex = groupedLocations.findIndex(
      (grouped) =>
        ((location.end_date === "" && grouped.end_date === "") ||
          (grouped.start_date === location.start_date &&
            grouped.end_date === location.end_date)) &&
        grouped.reason === location.reason
    );
    exisitingEntryIndex !== -1
      ? groupedLocations[exisitingEntryIndex].location_ids.push(location.id)
      : groupedLocations.push({
          ...location,
          location_ids: [location.id],
        });
  });
  return groupedLocations;
};

// Function to run in catch blocks in case any api fail
export const handleApiCatch = (error: any) => {
  if (
    typeof error.response !== "undefined" &&
    typeof error.response.data !== "undefined"
  ) {
    Toast_Func({ status: false, message: error.response.data.message });
  } else {
    Toast_Func({ status: false, message: "Data Not found" });
  }
};

// A function to check weather some specific UI element should show on UI or not on the base of permissions
export const isUserAuthorizedForThisFeature = (
  parentModule: string,
  childModule: string
) => {
  const permissions = getPermissionsStateRef()?.permissions;
  if (!permissions) return true;
  if (parentModule) {
    const modulePermissions = permissions.find(
      (permission) => permission.name === parentModule
    );
    if (modulePermissions) {
      if (childModule) {
        if (
          modulePermissions.permissions.find(
            (permission) => permission.name === childModule
          )
        )
          return true;
        else return false;
      } else return true;
    } else return false;
  } else return true;
};

export const isUserAuthorizedForAnyAction = (actions: IActionDetails[]) => {
  return actions.filter((action) => action.visibility !== "none").length;
};

// Function to check if item name is equal to those who have half brink item capacity

interface Option {
  id: number;
  name: string;
}
export const is_item_have_half_brink_capacity = (
  category_ids: number[],
  categories?: Option[]
) => {
  if (!categories) return false;
  const halfBrinkAssociatedCategories = categoriesName.map((name) =>
    name.toLocaleLowerCase().replace(/[^a-zA-Z ]/g, "")
  );
  const isValid = categories.find(
    (category: Option) =>
      halfBrinkAssociatedCategories.includes(
        category.name.toLocaleLowerCase().replace(/[^a-zA-Z ]/g, "")
      ) && category_ids.find((id) => id == category.id)
  );
  if (isValid) return true;
  return false;
};
export const is_item_have_half_brink_capacity_catering = (
  category_ids: number[],
  categories?: Option[]
) => {
  if (!categories) return false;
  if (categoriesNameCatering.length < 1) return false;
  const halfBrinkAssociatedCategories = categoriesNameCatering?.map(
    (name: string) => name.toLocaleLowerCase().replace(/[^a-zA-Z ]/g, "")
  );
  const isValid = categories.find(
    (category: Option) =>
      halfBrinkAssociatedCategories.includes(
        category.name.toLocaleLowerCase().replace(/[^a-zA-Z ]/g, "")
      ) && category_ids.find((id) => id == category.id)
  );

  if (isValid) return true;
  return false;
};

// Function to check if item name is equal to those who have half brink item capacity

export const is_category_have_half_brink_capacity = (category_name: string) => {
  return categoriesName
    .map((name) => name.toLocaleLowerCase().replace(/[^a-zA-Z ]/g, ""))
    .includes(category_name.toLocaleLowerCase().replace(/[^a-zA-Z ]/g, ""));
};

export const disabled_locations_date_range_grouping = (data: ItemConfig) => {
  const list: ItemConfig = {};
  Object.keys(data).forEach((id: string) => {
    const groupedLocations: StatusDetails[] = [];
    data[id].forEach((location: any) => {
      const exisitingEntryIndex = groupedLocations.findIndex(
        (grouped) =>
          ((location.end_date === "" && grouped.end_date === "") ||
            (grouped.start_date === location.start_date &&
              grouped.end_date === location.end_date)) &&
          grouped.reason === location.reason
      );
      exisitingEntryIndex !== -1
        ? groupedLocations[exisitingEntryIndex].location_ids.push(
            location.location_id
          )
        : groupedLocations.push({
            ...location,
            location_ids: [location.location_id],
          });
    });
    list[id] = groupedLocations;
  });
  return list;
};

// Enumerates days between days
export const enumerateDaysBetweenDates = (
  startDate: string,
  endDate: string
) => {
  const dates = [];
  const currDate = moment(startDate).startOf("day");
  const lastDate = moment(endDate).startOf("day");
  while (currDate.add(1, "days").diff(lastDate) <= 0)
    dates.push(currDate.clone().toDate());
  return dates;
};

// Conversion of utc time to local
export const convert_utc_time_to_local = (time_in_utc: string) => {
  return time_in_utc
    ? moment.utc(moment.utc(time_in_utc, "HH:mm:ss")).local().format("HH:mm")
    : "";
};

//Converting time value in 12 hours format
export const convert_time_in_12_hours_format = (time: string) => {
  return time
    ? moment.utc(moment.utc(time, "HH:mm:ss")).local().format("LT")
    : "";
};

export const convert_time_stamp_in_12_hours_format = (time: string) => {
  return time
    ? moment
        .utc(moment.utc(moment(time).format("HH:mm:ss"), "HH:mm:ss"))
        .local()
        .format("LT")
    : "";
};

export const convert_time_in_12_hours_without_utc_conversion = (time: string) => {
  return time
    ? moment(time, "HH:mm:ss").format("LT")
    : "";
};

export const getTimeSlots = (slot: number) => {
  const startTime = moment("00:00:00", "HH:mm:ss");
  const timeStops = [];

  while(startTime < moment("24:00:00", "HH:mm:ss")){
    timeStops.push(moment(startTime).format("HH:mm:ss"));
    startTime.add(slot, 'minutes');
  }
  return timeStops;
}