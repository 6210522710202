import { SET_SEARCH_TEXT, SET_SEARCH_MANAGER } from "../types";

const initialState = {
  search: "",
  searchManager:""

};

const setSearchTextReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SEARCH_TEXT:
      return {
        ...state,
        search: action.payload,
      };
      case SET_SEARCH_MANAGER:
        return {
          ...state,
          searchManager: action.payload,
        };
     

    default:
      return state;
  }
};

export default setSearchTextReducer;
