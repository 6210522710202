import React from "react";
import classes from './BreadCrumbs.module.scss'
import { makeStyles } from '@material-ui/core/styles';
import {
    Breadcrumbs as MUIBreadcrumbs,
    Link,
    Typography
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const Breadcrumbs = props => {
    const clas = useStyles();
    const {
        history,
        location: { pathname }
    } = props;

    const pathnames = pathname.split("/").filter(x => x);
    if(['edit', "mark86", "disable"].includes(pathnames[pathnames.length - 2])){
        pathnames[pathnames.length - 2] = pathnames[pathnames.length - 2]+' - '+pathnames[pathnames.length - 1];
        pathnames.splice(-1,1);
    }
    // To show location id in proper format with configuration type
    if(['edit'].includes(pathnames[pathnames.length - 3])){
        pathnames[pathnames.length - 3] = pathnames[pathnames.length - 1]+' - '+pathnames[pathnames.length - 2];
        pathnames.splice(2,2);
    }
    if(pathnames[pathnames.length - 2] === 'clone'){
        pathnames[pathnames.length - 2] = pathnames[pathnames.length - 2]+' - '+pathnames[pathnames.length - 1];
        pathnames.splice(-1,1);
    }
    if(pathnames[pathnames.length - 2] === 'apps'){
        pathnames[pathnames.length - 2] = pathnames[pathnames.length - 2]+' - '+pathnames[pathnames.length - 1];
        pathnames.splice(-1,1);
    }
    if(pathnames[pathnames.length - 2] === 'changePassword'){
        pathnames[pathnames.length - 2] = pathnames[pathnames.length - 2];
        pathnames.splice(-1,1);
    }
    if(pathnames[pathnames.length - 2] === 'default'){
        pathnames[pathnames.length - 2] = 'Default Permission';
        pathnames.splice(-1,1);
    }
    if(pathnames[pathnames.length - 2] === 'quicksight-dashboard'){
        pathnames[pathnames.length - 2] = 'QuickSight Dashboard';
        pathnames.splice(-1,1);
    }
    let customClass = ((pathnames[0] === 'menus' || 
    pathnames[0] === 'menu-markets' ||
    pathnames[0] === 'items' ||
    pathnames[0] === 'ingredients' ||
    pathnames[0] === 'categories') ? classes.sidebar_breadcrumb : '');
    return (
        <div className={`${customClass} ${clas.root} ${pathnames.length ? classes.breadcrumb_box : null}`}>
            <MUIBreadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                {pathnames.length > 0 ? (
                    <Link className={classes.breadcrumb_text} onClick={() => history.push("/")}>Dashboard</Link>
                ) : (
                        <Typography className={classes.breadcrumb_text+ ' '+classes.breadcrumb_text_dashboard}> Dashboard </Typography>
                    )}
                {pathnames.map((name, index) => {
                    const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                    const isLast = index === pathnames.length - 1;
                    return isLast ? (
                        <Typography className={classes.breadcrumb_text_selected} key={name}>{name}</Typography>
                    ) : (
                            <Link className={classes.breadcrumb_text} key={name} onClick={() => history.push(routeTo)}>
                                {name}
                            </Link>
                            
                        );
                })}
            </MUIBreadcrumbs>
        </div>
    );
};

export default withRouter(Breadcrumbs);
