import { SET_LOAD_WIDJET_FALSE, SET_LOAD_WIDJET_TRUE } from "../types";

const initialState = {
  loadWidjet: false,
};

const loadWidjetOnMasquerading = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOAD_WIDJET_TRUE:
      return {
        ...state,
        loadWidjet: true,
      };
    case SET_LOAD_WIDJET_FALSE:
      return {
        ...state,
        loadWidjet: false,
      };
    default:
      return state;
  }
};

export default loadWidjetOnMasquerading;
