import React, { useContext, useEffect } from "react";
import classes from "./App.module.scss";
import { BrowserRouter } from "react-router-dom";
import Routes from "./containers/routes/Routes";
import AuthContextContainer from "./containers/auth/AuthContextContainer";
import NavbarContainer from "./containers/navbar/Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "./../src/containers/auth/AuthContext";
import { AuthPermissionsProvider } from "./../src/containers/authPermissions/AuthPermissionsContext";
import { useSelector, useDispatch } from "react-redux";
const App: React.FC = () => {
  const authContext = useContext(AuthContext);
  const isBorderRed = useSelector((state: any) => {
    return state.borderRed.isBorderRed;
  });

  /**
   * To automatically logout after 24 hours
   */
  function expireTime() {
    var hours = 24;
    let now : number = new Date().getTime();
    var setupTime = localStorage.getItem('SETUP_TIME');
    if (setupTime == null) {
        localStorage.setItem('SETUP_TIME', String(now) )
    } else {
      let timeDiff = now - Number(setupTime)
      if(timeDiff > hours*60*60*1000) {
        localStorage.removeItem("token");
        localStorage.removeItem("AUTH_NAME");
        localStorage.removeItem("AUTH_ROLE");
        localStorage.removeItem("AUTH_ID");
        localStorage.removeItem("AUTH_TOKEN");
        localStorage.removeItem("SETUP_TIME");
      }
    }
  }

  useEffect(() => {}, [isBorderRed]);
  useEffect(() => {expireTime()}, []);
  return (
    <div className={classes.App}>
      <BrowserRouter>
        <AuthContextContainer>
          <AuthPermissionsProvider>
            <NavbarContainer>
              <ToastContainer />
              <Routes />
            </NavbarContainer>
          </AuthPermissionsProvider>
        </AuthContextContainer>
      </BrowserRouter>
    </div>
  );
};

export default App;
