/** @format */

//user reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";
//UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_BORDER_RED_TRUE = "SET_BORDER_RED_TRUE";
export const SET_BORDER_RED_FALSE = "SET_BORDER_RED_FALSE";
export const SET_LOAD_WIDJET_TRUE = "SET_LOAD_WIDJET_TRUE";
export const SET_LOAD_WIDJET_FALSE = "SET_LOAD_WIDJET_FALSE";
export const SET_SHOW_DROPDOWN_FALSE = "SET_SHOW_DROPDOWN_FALSE";
export const SET_SHOW_DROPDOWN_TRUE = "SET_SHOW_DROPDOWN_TRUE";
export const SET_USERNAME = "SET_USERNAME";
export const SET_ROLENAME = "SET_ROLENAME";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_SEARCH_MANAGER = "SET_SEARCH_MANAGER";

//Dashboard reducer types
export const CLEAR_DASHBOARD_URL = "CLEAR_DASHBOARD_URL";
export const SET_DASHBOARD_URL = "SET_DASHBOARD_URL";
