import { SET_LOAD_WIDJET_FALSE, SET_LOAD_WIDJET_TRUE } from "../types";

export const setLoadWidjetTrue = () => {
  return {
    type: SET_LOAD_WIDJET_TRUE,
  };
};

export const setLoadWidjetFalse = () => {
  return {
    type: SET_LOAD_WIDJET_FALSE,
  };
};
