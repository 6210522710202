import { SET_SHOW_DROPDOWN_FALSE, SET_SHOW_DROPDOWN_TRUE } from "../types";

const initialState = {
  show: false,
};

const hideAndShowReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SHOW_DROPDOWN_TRUE:
      return {
        ...state,
        show: true,
      };
    case SET_SHOW_DROPDOWN_FALSE:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};

export default hideAndShowReducer;
