import React from "react";
import styles from "./Spinner.module.scss";
import Image from "../Atoms/Image/Image";
interface SpinnerProps {
  lightBg?: boolean;
}

const Spinner: React.FunctionComponent<SpinnerProps> = ({ lightBg }) => {
  const styles = {
    display: "block",
    width: "200px",
    maxHeight: "100%",
    maxWidth: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  };

  return (
    <Image
      style={styles}
      source={require("../../images/Loader2.gif").default}
    ></Image>
  );
};

export default Spinner;
