import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AuthConsumer } from "../auth/AuthContext";
import NavbarLinks from "../../components/Navbar/NavBarLinks";
import GlobalAlert from "../../components/Navbar/GlobalAlert";

export interface NavbarInterface {
  isHidden: boolean;
}
interface Props extends RouteComponentProps {
  children: React.ReactNode;
}

const NavbarContainer: React.FunctionComponent<Props> = ({
  history,
  children,
}) => {
  return (
    <AuthConsumer>
      {(values) => {
        return (
          <React.Fragment>
            {values.checkAuthentication() ? (
              <>
                <NavbarLinks
                  logOut={values.logoutUser}
                  logoutMasqueradedUser={values.logoutMasqueradedUser}
                ></NavbarLinks>
                <GlobalAlert></GlobalAlert>
              </>
            ) : null}
            {children}
          </React.Fragment>
        );
      }}
    </AuthConsumer>
  );
};

export default withRouter(NavbarContainer);
