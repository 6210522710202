export enum ContentType {
  json = 'application/json',
  multipart = 'multipart/form-data',
}

export const AppsImagesNames:any = Object.freeze({
    MARKETS : 'Markets.svg',
    LOCATIONS : 'Locations.svg',
    USERS : 'Users.svg',
    MENU : 'Menu.svg',
    CAROUSELS : 'Carousels.svg',
    AUDIT : 'Audit.svg',
    NOTIFICATIONENGINE : 'NotificationEngine.svg',
    CONFIGURATIONS : 'Configurations.svg',
    PREP : 'Prep.svg',
    SCHEDULER : 'Scheduler.svg',
    SYSTEMS : 'Systems.svg',
    INVENTORY : 'Inventory.svg'
})

export const roleNames:any = {
  'admin': 'admin',
  'operating partners' : 'OP',
  'manager' : 'manager',
  'talent mentors' : 'TM',
  'district manager' : 'DM',
  'Corporate': 'corporate'
}
