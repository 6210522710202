import { toast } from "react-toastify";
export interface Toast {
  status: boolean;
  message: any;
}
export function Toast_Func(data: Toast) {
  if (data.status === true) {
    toast.success(data.message, {
      position: "top-center",
    });
  } else {
    if (typeof data.message === "object") {
      for (var k in data.message) {
        if (Array.isArray(data.message[k])) {
          for (var j in data.message[k]) {
            toast.error(data.message[k][j], {
              position: "top-center",
            });
          }
        } else {
          toast.error(data.message, {
            position: "top-center",
          });
        }
      }
    } else {
      toast.error(data.message, {
        position: "top-center",
      });


    }
    if (
      data.message === "The request is not authenticated, kindly login first."
    ) {

      localStorage.removeItem("AUTH_TOKEN");
      localStorage.removeItem("AUTH_NAME");
      localStorage.removeItem("AUTH_ROLE");
      localStorage.removeItem("AUTH_ID");
      document.cookie = "__guid__=; Max-Age=0; domain=.zupas.com";
      document.cookie = "__token__=; Max-Age=0; domain=.zupas.com";
      document.cookie = "__suid__=; Max-Age=0;";
      document.cookie = "__suid__=; Max-Age=0; domain=.zupas.com";
      document.cookie = "__suid__=; Max-Age=0; domain=zupas.com";
      document.cookie = "__suid__=; Max-Age=0; domain=systems.zupas.com";
      document.cookie = "__suid__=; Max-Age=0; domain=controlcenter.zupas.com";
      window.location.replace("/login");
    }
  }
}
