import { SET_DASHBOARD_URL, CLEAR_DASHBOARD_URL } from "./../types";

export const setDashboardUrl = (dashboardUrl: string) => {
  return {
    type: SET_DASHBOARD_URL,
    payload: {
      dashboardUrl,
    },
  };
};

export const clearDashboardUrl = () => {
  return {
    type: CLEAR_DASHBOARD_URL,
  };
};
